import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "@/components/LibraryTable/ColumnHeader";
import { LibraryRecord } from "@/api/types/library";
import { CellContentName } from "@/components/LibraryTable/columns/CellContentName";
import { CellContentIcon } from "@/components/LibraryTable/columns/CellContentIcon";
import { CellContentTags } from "@/components/LibraryTable/columns/CellContentTags";
import { CellContentActions } from "@/components/LibraryTable/columns/CellContentActions";
import { PrimaryAssetRecord } from "@/api/types/node";

export const useLibraryTableColumns = (): ColumnDef<PrimaryAssetRecord>[] => {
  return [
    {
      accessorKey: "kind",
      header: ({ column }) => <div className="w-4" />,
      size: 10,
      cell: CellContentIcon,
    },
    {
      accessorKey: "display_name",
      enableSorting: true,
      size: 500,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Asset" />
      ),
      cell: CellContentName,
    },
    {
      accessorKey: "tags",
      size: 240,
      enableSorting: false,
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title="Tags"
          className="text-xs text-primary"
        />
      ),
      cell: (props) => <CellContentTags {...props} />,
    },
    {
      accessorKey: "updated_at",
      size: 10,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Modified" />
      ),
      cell: ({ row }) => {
        const updatedAt = row.original.updated_at;
        const date = new Date(updatedAt);
        return <div>{date.toLocaleDateString()}</div>;
      },
    },
    {
      id: "actions",
      size: 10,
      cell: ({ row }) => (
        <CellContentActions
          id={row.original.id}
          primary_asset_type={row.original.kind}
          content_name={row.original.display_name}
        />
      ),
    },
  ];
};
